.Banner {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 20px;
    z-index: 2000;
}

a {
    text-decoration: underline;
    color: inherit;
    /* This will make the link have the same color as the surrounding text */
}