.skill-icons-gird {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* margin-top: 5vh; */
    gap: 0.8rem;
}

.skill-icons-gird a {
    color: white;
    text-decoration: none;
}

.skill img {
    height: 3rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    transition-duration: 0.3s;
    cursor: pointer;
    background-color: white;
}

.skill img:hover {
    box-shadow: none;
    transform: scale(1.1);
    transition-duration: 0.3s;
}

.skill-icons-gird p {
    font-weight: 300;
    font-size: 0.7rem;
    text-align: center;
    margin-top: 0.7rem;
}

@media only screen and (max-width: 950px) {
    .skill-icons-gird {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 5vh;
        gap: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .skill-icons-gird {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 5vh;
        gap: 1rem;
    }
}

@media only screen and (max-width: 350px) {
    .skill-icons-gird {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 5vh;
        gap: 1rem;
    }
}

@media only screen and (max-width: 475px) {
    .skill img {
        height: 5rem;
        width: 5rem;
        padding: 0.75rem;
    }
}