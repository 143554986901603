.about {
    background-color: #2c323f;
}

.about-text {
    width: 100%;
    max-width: 600px;
    color: white
}

.about-text>ul {
    list-style-type: none;
}

.about-text>ul>li {
    margin-bottom: 10px;
}

.typewriter {
    display: flex;
    align-items: center;
}

.typewriter-start {
    padding-right: 5px;
}

.typewriter>p {
    margin: 0;
}

.textLink {
    color: dodgerblue;
    cursor: pointer;
}

.location-wrapper {
    display: flex;
    align-items: center;
    border-top: 1px solid white;
    width: 70%;
}

.skills-wrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.skills {
    position: relative;
    padding-left: 0;
    list-style: none;
    font-size: 3rem;
    margin: 0 auto;
    width: 90%;
}

.skill-bar-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    background-color: #3e4555;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 10px;
}

.skill-bar {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: 30px;
    background-color: dodgerblue;
    border-radius: 2px 0px 0px 2px;
}

@media screen and (min-width: 1300px) {
    .about-content {
        display: flex;
        justify-content: space-evenly;
    }
    .skill-name {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px 0px 0px 2px;
        left: 0;
        width: 100px;
        height: 30px;
        font-size: 12px;
        background-color: rgb(80, 137, 156);
        font-weight: 600;
        color: white;
    }
}

.eitlink:link {
    color: lightblue;
}

@media screen and (max-width: 1300px) and (min-width: 600px) {
    .about-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .skill-name {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px 0px 0px 2px;
        left: 0;
        width: 80px;
        height: 30px;
        font-size: 12px;
        background-color: #39b175;
        font-weight: 600;
        color: white;
    }
}

@media screen and (max-width: 599px) {
    .about-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .skills {
        width: 100%;
    }
    .skill-name {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px 0px 0px 2px;
        left: 0;
        width: 65px;
        height: 30px;
        font-size: 10px;
        background-color: #39b175;
        font-weight: 600;
        color: white;
    }
}