@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
html,
#root,
body {
    height: 100%;
    margin: 0;
}

@media screen and (min-width: 600px) {
    body {
        font-size: 18px;
    }
    section {
        position: relative;
        padding: 6rem;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 599px) {
    body {
        font-size: 16px;
    }
    section {
        position: relative;
        padding: 1rem;
        padding-bottom: 0;
    }
}

body {
    margin: 0;
    padding: 0;
    background: #fefefe;
    /*FIND colore di sfondo prima pagina, valore iniziale: 272b33, scuro: 161616*/
    color: #333;
    font-family: 'Montserrat', sans-serif;
    min-width: 320px;
    overflow-x: hidden;
}

button>span {
    font-family: 'Montserrat', sans-serif;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

section h1 {
    text-align: center;
}