.experience {
    position: relative;
    background-color: #2c323f;
}

.experience-content {
    margin: 0 auto;
    max-width: 1400px;
}

.experience-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
    list-style: none;
    margin: 0 auto;
}

.experience-list>li {
    border-radius: 5px;
    margin: 20px 10px;
    width: 390px;
    min-width: 290px;
}

.experience-list>li>div {
    height: 100%;
}

.textLink {
    color: dodgerblue;
    cursor: pointer;
}