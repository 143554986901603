@media screen and (min-width: 599px) {
    .profile {
        width: 180px !important;
        height: 180px !important;
    }
}

@media screen and (min-width: 1500px) {
    .profile {
        width: 500px !important;
        height: 500px !important;
    }
}

@media screen and (max-width: 599px) {
    .profile {
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-height: 599px) {
    .scroll-down {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 170px;
    }
}

@media screen and (max-height: 599px) and (min-height: 410px) {
    .scroll-down {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 100px;
    }
}

@media screen and (max-height: 409px) {
    .scroll-down {
        display: none;
    }
}

.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: calc(100vh + 63px);
    z-index: 1000;
}

.greeting {
    margin: -63px 20px 0 20px;
}

.hide {
    display: none;
}

.profile {
    border-radius: 50%;
    /* -webkit-filter: drop-shadow(2px 1px 0 dodgerblue) drop-shadow(-1px -1px 0 dodgerblue); */
    /* filter: drop-shadow(2px 2px 0 dodgerblue) drop-shadow(-2px -2px 0 dodgerblue) drop-shadow(-2px 2px 0 dodgerblue) drop-shadow(2px -2px 0 dodgerblue); */
    border: 0.35rem solid dodgerblue;
}

.greeting-text {
    margin: 15px;
}

.name {
    color: dodgerblue;
}

.logoImage:hover {
    cursor: pointer;
}

.links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    max-width: 60px;
    margin: 20px auto 0 auto;
}

.links a,
.links img {
    width: 50px;
    height: 50px;
}

.scroll-down-element {
    display: inline-block;
}

.wave-emoji {
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-name: wave;
    display: inline-block;
    transform-origin: 70% 70%;
    animation-fill-mode: forwards;
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: 100%;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-10deg);
    }
    20% {
        transform: rotate(12deg);
    }
    30% {
        transform: rotate(-10deg);
    }
    40% {
        transform: rotate(9deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}