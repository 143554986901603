.section-title {
    font-size: 25pt;
    margin-bottom: 10px;
    color: white;
}

.underline {
    border-top: 4px solid dodgerblue;
    margin: 0 auto;
    margin-bottom: 15px;
    width: 70px;
}